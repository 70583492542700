<template>
  <div class="content">
    <PageHeader :title="$t('sms.history.header')" />
    <div class="page-content container-fluid">
      <div class="card">
        <div class="card-header">
          <div class="form-row">
            <div class="col-md-6 mb-6">
              <div class="form-group">
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('sms.history.lbl-search-id')"
                    v-model="form.id"
                    aria-label
                    aria-describedby="basic-addon2"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-primary btn-icon btn-search-id"
                      type="button"
                      @click="fetch(form.page)"
                    >
                      <i class="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="!$store.state.account.whitelabel_id"
              class="form-group col-lg-3 col-md-3 col-sm-12"
            >
              <select-input
                label="name"
                v-model="form.customer_ids"
                :reduce="(field) => field.id"
                :options="customers"
                :clearable="true"
                multiple
              />
            </div>
          </div>
          <div class="form-row">
            <div class="col-lg-4 col-md-4 m-top-10">
              <template>
                <div>
                  <date-range-picker
                    class="form-control"
                    value
                    :options="options"
                    @change="changeDate"
                  />
                </div>
              </template>
            </div>
            <div class="col-lg-2 col-md-2 m-top-10">
              <input
                class="form-control"
                :placeholder="$t('generic-str.lbl-sender')"
                v-model="form.from"
              />
            </div>
            <div class="col-lg-2 col-md-2 m-top-10">
              <input
                class="form-control"
                :placeholder="$t('generic-str.lbl-recipient')"
                v-model="form.to"
              />
            </div>
            <div class="col-lg-2 col-md-2 m-top-10">
              <select class="form-control" v-model="form.status">
                <option selected value>Status</option>
                <option value="accepted">{{$tc('generic-str.status.lbl-pending', 1)}}</option>
                <option value="read">{{$t('generic-str.status.lbl-readed')}}</option>
                <option value="sending">{{$t('generic-str.status.lbl-sending')}}</option>
                <option value="sent">{{$t('generic-str.status.lbl-sent')}}</option>
                <option value="failed">{{$t('generic-str.status.lbl-failure')}}</option>
                <option value="delivered">{{$t('generic-str.status.lbl-delivered')}}</option>
                <option value="received">{{$tc('generic-str.status.lbl-response', 2)}}</option>
              </select>
            </div>
            <div class="col-lg-2 col-md-2 m-top-10">
              <div class="form-group">
                <div class="input-group">
                  <div class>
                    <button class="btn btn-danger" @click="fetch(1)">
                      {{$t('generic-str.filter')}}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body block-el p-0">
          <div class="responsive-table">
            <table v-if="fetched" class="table">
              <thead class="bg-light">
                <tr>
                  <th scope="col">{{$t('generic-str.sent-date')}}</th>
                  <th scope="col">{{$t('generic-str.type')}}</th>
                  <th scope="col">{{$t('generic-str.lbl-sender')}}</th>
                  <th scope="col">{{$t('generic-str.lbl-recipient')}}</th>
                  <th scope="col">{{$tc('generic-str.message', 1)}}</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="message in messages"
                  :key="message.id"
                  :to="`history/${message.id}`"
                >
                  <td data-label="Data">
                    <div v-if="message.sent_at">
                      <router-link :to="`history/${message.id}`">
                        {{ message.sent_at | formatDate24}}
                      </router-link>
                    </div>
                    <div v-else>
                      <router-link :to="`history/${message.id}`">
                        {{ message.created_at | formatUtc}}
                      </router-link>
                    </div>
                  </td>
                  <td data-label="Tipo">
                    <template v-if="message.direction == 'outbound'">
                      {{$t('generic-str.send-2')}}
                    </template>
                    <template v-else-if="message.direction == 'inbound'">
                      {{$tc('generic-str.status.lbl-response', 1)}}
                    </template>
                  </td>
                  <td data-label="Remetente">{{ message.from }}</td>
                  <td data-label="Destinatário">{{ message.to }}</td>
                  <td
                    data-label="Mensagem"
                    class="max-200"
                  >
                    <router-link :to="`history/${message.id}`">
                        {{$t('generic-str.view-msg')}}
                      </router-link>
                  </td>
                  <td data-label="Status">
                    <span
                      v-if="message.status == 'accepted'"
                      class="badge badge-light"
                      >{{$tc('generic-str.status.lbl-pending', 1)}}</span
                    >
                    <span
                      v-else-if="message.status == 'queued'"
                      class="badge badge-secondary"
                      >{{$t('generic-str.status.lbl-queue')}}</span
                    >
                    <span
                      v-else-if="message.status == 'sending'"
                      class="badge badge-accent"
                      >{{$t('generic-str.status.lbl-sending')}}</span
                    >
                    <span
                      v-else-if="message.status == 'sent'"
                      class="badge badge-info"
                      >{{$t('generic-str.status.lbl-sent')}}</span
                    >
                    <span
                      v-else-if="message.status == 'failed'"
                      class="badge badge-danger"
                      v-tooltip.top="
                        `${$t('sms.infos.filters.status.lbl-error')} ${message.error_code}: ${message.error_message}`
                      "
                      >{{$t('generic-str.status.lbl-failure')}}</span
                    >
                    <span
                      v-else-if="message.status == 'delivered'"
                      class="badge badge-success"
                      >{{$t('generic-str.status.lbl-delivered')}}</span
                    >
                    <span
                      v-else-if="message.status == 'read'"
                      class="badge badge-info"
                      >{{$t('generic-str.status.lbl-readed')}}</span
                    >
                    <span
                      v-else-if="message.status == 'undelivered'"
                      class="badge badge-dark"
                      v-tooltip.top="
                        `${$t('sms.infos.filters.status.lbl-error')} ${message.error_code}: ${message.error_message}`
                      "
                      >{{$t('generic-str.status.lbl-unavailable')}}</span
                    >
                    <span
                      v-else-if="message.status == 'received'"
                      class="badge badge-success"
                      >{{$tc('generic-str.status.lbl-response', 1)}}</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-if="!fetched" class="qt-block-ui" />
            <div
              v-if="!messages.length && fetched"
              class="text-center table-placeholder"
            >
              <br>
              <i class="fas fa-comment font-size-80"></i>
              <h5 class="card-title m-t-20">{{$t('warnings.rcs.none-msg')}}</h5>
            </div>
            <!-- <div v-else class="relative qt-block-ui" style="padding: 120px" /> -->
            <br />
            <pagination :lastPage="pages" @change="fetch" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment';
import RCSService from '@/services/rcs.service';
import CustomerService from '@/services/customer.service';
import PageHeader from '@/components/PageHeader.vue';
import Pagination from '@/components/Pagination.vue';
import SelectInput from '@/components/SelectInput.vue';
// import moment from 'moment';

export default {
  name: 'SmsDashboard',
  components: {
    PageHeader,
    Pagination,
    SelectInput,
  },
  data() {
    return {
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      fetched: true,
      customers: [],
      messages: [],
      beginDate: '',
      endDate: '',
      form: {
        id: '',
        status: '',
        from: '',
        to: '',
        channel: 'rcs',
        created_at: {
          gte: '',
          lte: '',
        },
        page: 1,
      },
      pages: 1,
      exporting: false,
    };
  },
  created() {
    if (!this.$store.state.account.whitelabel_id) {
      this.fetchCustomers();
    }
  },
  mounted() {
    this.changeDate();
  },
  methods: {
    fetch(page) {
      this.form.page = page;
      this.fetched = false;
      RCSService.filterMessages(this.form).then(
        (response) => {
          this.fetched = true;
          this.messages = response.data;
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
          console.log(JSON.stringify(error.response.data));
        },
      );
    },
    fetchCustomers() {
      CustomerService.getCustomers().then(
        (customers) => {
          this.customers = customers;
          this.customers.push(this.$store.state.account);
        },
        (error) => {
          this.content = error;
        },
      );
    },
    changeDate(date) {
      if (date) {
        this.beginDate = moment(date[0], 'DD/MM/YYYY').format(
          'YYYY-MM-DD 00:00:00',
        );
        this.endDate = moment(date[1], 'DD/MM/YYYY').format(
          'YYYY-MM-DD 23:59:59',
        );
      } else {
        this.beginDate = moment().format('YYYY-MM-DD 00:00:00');
        this.endDate = moment().format('YYYY-MM-DD 23:59:59');
      }
      this.form.created_at.gte = this.beginDate;
      this.form.created_at.lte = this.endDate;
    },
  },
};
</script>

<style scoped lang="scss">
.btn-search-id {
  height: 35px;
}
.btn-export {
  margin-left: 1px;
  padding: 8px 20px !important;
}
.m-top-10 {
  margin-top: 10px;
}
</style>
